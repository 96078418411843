<template>
    <section
        class="VideoBlock"
        :class="{ 'VideoBlock--in-view': inView }"
        :id="sliceId"
        ref="videoBlock"
    >
        <VimeoVideo
            :video-id="slice.primary.vimeo_video_id || ''"
            :player-id="slice.id.replace('$', '-')"
            :poster="slice.primary.video_poster?.url || undefined"
        />
    </section>
</template>

<script setup lang="ts">
import { type Content } from '@prismicio/client'

// The array passed to `getSliceComponentProps` is purely optional.
// Consider it as a visual hint for you when templating your slice.
const props = defineProps(
    getSliceComponentProps<Content.VideoBlockSlice>([
        'slice',
        'index',
        'slices',
        'context',
    ]),
)
const sliceId = props.slice.id.replace('$', '-') + '-video-block'
const videoBlockObserver = ref()
const videoBlock = ref()
const inView = ref()

onMounted(() => {
    videoBlockObserver.value = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                inView.value = true
                observer.disconnect()
            }
        })
    })

    videoBlockObserver.value.observe(videoBlock.value)
})
</script>

<style lang="scss">
.TextHero + .VideoBlock {
    margin-top: -2rem;
}

.VideoBlock:has(+ .ExpanderCardCarousel) {
    @include small-only {
        margin-bottom: 3rem;
    }
}

.VideoBlock {
    grid-column: 2 / span 22;
    margin-top: 5.5rem;
    opacity: 0;
    transform: translateY(10%);
    transition: all 1s ease;

    @include medium-up {
        margin-top: 11.25rem;
    }
}

.VideoBlock--in-view {
    opacity: 1;
    transform: translateY(0);
}
</style>
